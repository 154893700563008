<template>
  <div>

    <b-button style="margin-right: 0; margin-left: auto; display: block" variant="outline-primary" v-b-modal.configureURL>
      <b-icon icon="bookmark-check-fill"></b-icon>
      API-URL konfigurieren
    </b-button>

    <b-modal id="configureURL" size="lg" title="Configure API-URL" 
      ok-title="Save"
      @ok="handleOk"
      @show="resetModal"
      @hidden="resetModal"
    >
      <b-form-input id="input-title"
          v-model="apiUrl" 
          :text="apiUrl" 
       />

    </b-modal>

    <div class="h-md-100 d-md-flex align-items-center p-5 text-center justify-content-center">
      <div class="group_bar">
                    <b-button v-if="status.engine_left == false" variant="success" @click="startLeft()">Power Left On</b-button>
                    <b-button v-else variant="danger" @click="stopLeft()">Power Left Off</b-button>
                    <br>
                    <b-button v-if="status.direction_left == false" variant="success" @click="forwardsLeft()">Forwards</b-button>
                    <b-button v-else variant="danger" @click="backwardsLeft()">Backwards</b-button>
      </div>
      <div class="group_bar_middle">
                    <b-button variant="success" @click="startBoth()">Power Both On</b-button>
                    <br>
                    <br>
                    <b-button style="" variant="danger" @click="stopBoth()">Power Both Off</b-button>
      </div>
      <div class="group_bar">
                    <b-button v-if="status.engine_right == false" variant="success" @click="startRight()">Power Right On</b-button>
                    <b-button v-else variant="danger" @click="stopRight()">Power Right Off</b-button>
                    <br>
                    <b-button v-if="status.direction_right == false" variant="success" @click="forwardsRight()">Forwards</b-button>
                    <b-button v-else variant="danger" @click="backwardsRight()">Backwards</b-button>
      </div>
    </div>
  </div>
</template>

<script>
import {startBoth, startRight, startLeft, stopBoth, stopRight, stopLeft, forwardsLeft, forwardsRight, backwardsLeft, backwardsRight, getStatus} from '@/js/tank-rest-client'


export default {

  name: "Home",
  components: {

  },
  data() {
    return {
      status: {
        engine_left: false,
        engine_right: false,
        direction_left: true,
        direction_right: true,
      },
      apiUrl: this.$api_url
    };
  },
  methods: {
    updateAPIURL() {
      this.$api_url = this.apiUrl
      localStorage.setItem("api_url", this.apiUrl)
    },
    handleOk(bvModalEvt) {
      this.updateAPIURL()
      this.showToastMessage(
        "API-URL erfolgreich geupdated",
        `Die API-URL wurde erfolgreich geupdated`,
        "success"
       ); 
    },
    resetModal() {
      this.apiUrl = this.$api_url
    },
    showToastMessage(title, msg, variant) {
      this.$bvToast.toast(msg, {
        title: title,
        variant: variant,
        solid: true,
        toaster: "b-toaster-top-right",
        autoHideDelay: 4000,
        appendToast: true
      });
    },
    startBoth() {
      startBoth(this).then(result => {
        this.setStatus()
      })
    },
    stopBoth() {
      stopBoth(this).then(result => {
        this.setStatus()
      })
    },
    startLeft() {
      startLeft(this).then(result => {
        this.setStatus()
      })
    },
    stopLeft() {
      stopLeft(this).then(result => {
        this.setStatus()
      })
    },
    startRight() {
      startRight(this).then(result => {
        this.setStatus()
      })
    },
    stopRight() {
      stopRight(this).then(result => {
        this.setStatus()
      })
    },
    forwardsLeft() {
      forwardsLeft(this).then(result => {
        this.setStatus()
      })
    },
    backwardsLeft() {
      backwardsLeft(this).then(result => {
        this.setStatus()
      })
    },
    forwardsRight() {
      forwardsRight(this).then(result => {
        this.setStatus()
      })
    },
    backwardsRight() {
      backwardsRight(this).then(result => {
        this.setStatus()
      })
    },
    setStatus() {
      getStatus(this).then(status => {
        this.status = status
      }).catch(error => {
        this.showToastMessage(
          "Verbindung zu API fehlgeschlagen",
          `${error}`,
          "danger"
        ); 
      })
    }
  },
  created: function() {
    this.setStatus()
  },
};
</script>


<style>
@media (min-width: 768px) {
    .h-md-100 { height: 75vh; }
}

.group_bar {
  width: 40%;
  font-size: 6vh;
  text-align: center;
  display: inline-block;
  background-color: white;
}

.group_bar_middle {
  width: 20%;
  font-size: 6vh;
  text-align: center;
  display: inline-block;
  background-color: white;
}
</style>
