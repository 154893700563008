// main JS file for instantiating the application

import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import { BootstrapVue, BootstrapVueIcons } from "bootstrap-vue";

import config from "@/config";

// BootstrapVue for UI components and icons
Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons)

Vue.config.productionTip = false;

if (localStorage.getItem("api_url") == null) {
  Vue.prototype.$api_url = config.apiBaseUrl
} else {
  Vue.prototype.$api_url = localStorage.getItem("api_url")
}

new Vue({
  router,
  render: (h) => h(App)
}).$mount("#app");
