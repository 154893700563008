import config from "@/config";
import axios from "axios";

export const startBoth = async function (vueInstance) {
    return axios.post(`${vueInstance.$api_url}/start/both`).then((response) => {
        return response.data;
    });
}

export const startRight = async function (vueInstance) {
    return axios.post(`${vueInstance.$api_url}/start/right`).then((response) => {
        return response.data;
    });
}

export const startLeft = async function (vueInstance) {
    return axios.post(`${vueInstance.$api_url}/start/left`).then((response) => {
        return response.data;
    });
}

export const stopBoth = async function (vueInstance) {
    return axios.post(`${vueInstance.$api_url}/stop/both`).then((response) => {
        return response.data;
    });
}

export const stopRight = async function (vueInstance) {
    return axios.post(`${vueInstance.$api_url}/stop/right`).then((response) => {
        return response.data;
    });
}

export const stopLeft = async function (vueInstance) {
    return axios.post(`${vueInstance.$api_url}/stop/left`).then((response) => {
        return response.data;
    });
}

export const forwardsLeft = async function (vueInstance) {
    return axios.post(`${vueInstance.$api_url}/direction/left/forwards`).then((response) => {
        return response.data;
    });
}

export const forwardsRight = async function (vueInstance) {
    return axios.post(`${vueInstance.$api_url}/direction/right/forwards`).then((response) => {
        return response.data;
    });
}

export const backwardsLeft = async function (vueInstance) {
    return axios.post(`${vueInstance.$api_url}/direction/left/backwards`).then((response) => {
        return response.data;
    });
}

export const backwardsRight = async function (vueInstance) {
    return axios.post(`${vueInstance.$api_url}/direction/right/backwards`).then((response) => {
        return response.data;
    });
}

export const getStatus = async function (vueInstance) {
    return axios.get(`${vueInstance.$api_url}/status`).then((response) => {
        return response.data;
    });
}